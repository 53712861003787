<template>
    <component :is="SkinComponent" v-model="form" :code="code" @save="save"></component>
</template>

<script>
import api from "@/api";

export default {
    props: {
        skin: { type: String, default: null },
        code: { type: String, default: null },
    },

    data() {
        return {
            form: {
                _work: this.$route?.query?._work,
                code: this.$props.code,

                subject: undefined,
                content: null,
                files: [],

                writer: {
                    _id: null,
                    name: null,
                    phone: null,
                    phone1: "010",
                    phone2: null,
                    phone3: null,
                    email: null,
                    email1: null,
                    email2: null,
                    email3: null,
                    postcode: null,
                    address1: null,
                    address2: null,
                },

                meta: {
                    name: null,
                    phone: null,
                    phone1: "010",
                    phone2: null,
                    phone3: null,
                    email: null,
                    email1: null,
                    email2: null,
                    email3: null,
                    postcode: null,
                    address1: null,
                    address2: null,
                    file: null,
                },
            },
        };
    },

    computed: {
        SkinComponent() {
            return () => import(`./skin/${this.$props.skin}/input.vue`);
        },
    },

    mounted() {
        if (!!this.$store.state.payload?._user) this.init();
    },

    methods: {
        async init() {
            const { user } = await api.v1.me.get();
            this.form.writer = user;

            const { name, phone, email, postcode, address1, address2 } = user;
            this.form.meta = { ...this.form.meta, name, phone, email, postcode, address1, address2 };
        },

        validate() {
            try {
                if(!this.form.meta.name) throw new Error("Please enter your name")
                if(!this.form.meta.company) throw new Error("Please enter your company")
                if(!this.form.meta.phone) throw new Error("Please enter your mobile")
                if(!this.form.meta.email) throw new Error("Please enter your email")
                if(!this.form.meta.message) throw new Error("Please enter your message")
                // if(!this.form.meta.file) throw new Error("Please enter your attachment")

                return true;
            } catch (error) {
                this.handleError(error);
            }
            return false;
        },

        async save() {
            if (this.validate()) {
                console.log(this.form);
                const { files = [], ...form } = this.form;
                const { form: { _id: _form } = {} } = await api.v1.forms.post(form);

                // for (const [index, file] of files.entries()) {
                //     await api.v1.forms.files.post({ _form, index }, file);
                // }
                if(this.form.meta.file) {
                    await api.v1.forms.file.post(_form, this.form.meta.file)
                }

                alert("저장되었습니다");

                this.$router.go(-1);
            }
        },

        handleError(error) {
            console.error(error);
            alert(error.repsonse ? error.response.data.message : error.message);
        },
    },
};
</script>
